import React from 'react';
import {
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    FormLabel,
    FormGroup,
    Tooltip,
    Checkbox,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    makeStyles, Box, CardHeader, Grid, IconButton, Dialog, DialogTitle, Table, TableHead, TableCell, TableBody, TableRow
} from "@material-ui/core";
import ASPECStyles from "../../../../../styles/jss/components/apps/ASPECStyles";
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles(ASPECStyles);

const ASPECPredefinedData = props => {
    const status = props.status === "SUCCESSFUL";
    const {data, updateData, setMissionMix} = props;
    const classes = useStyles();

    // Include damage code dialog
    const [includeDamageCodeDialog, setIncludeDamageCodeDialog] = React.useState(false);
    const handleIncludeDamageCodeDialogClose = () => setIncludeDamageCodeDialog(false)

    const handleChange = e => {
        // e.preventDefault();
        const target = e.target;
        const name = e.target.name;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        console.log("handleChange: ", value, name)
        updateData(value, name)
    }

    return (
        <Card style={{height: '100%'}}>
            {data.args.missionMix !== '' && (
              <Dialog onClose={handleIncludeDamageCodeDialogClose} aria-labelledby="simple-dialog-title" open={includeDamageCodeDialog}>
                  <DialogTitle id="simple-dialog-title">Damage Codes</DialogTitle>
                  <Table>
                      <TableHead>
                          <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>Code</TableCell>
                          </TableRow>
                      </TableHead>
                      <TableBody>
                          {props.config.occurrence_distributions && props.config.occurrence_distributions.map((occ_dist, idx) => (
                            <TableRow key={idx}>
                                <TableCell>{occ_dist.name}</TableCell>
                                <TableCell>{occ_dist.id}</TableCell>
                            </TableRow>
                          ))}

                      </TableBody>
                  </Table>
              </Dialog>
            )}

            <CardHeader
                title={'Predefined Data'}
                classes={{
                    title: classes.cardHeaderTitle,
                    root: classes.cardHeaderRoot
                }}
            />
            <CardContent>
                {status ? (
                    <div>
                        <Box mb={2}>
                            <Typography variant={'caption'}>Mission Mix:</Typography>
                            <Typography variant={'body2'}>{data.args.missionMix}</Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant={'caption'}>GAG Cycle Type:</Typography>
                            <Typography variant={'body2'}>{data.args.gagCycleType}</Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant={'caption'}>Include Damage Code:</Typography>
                            <Typography variant={'body2'}>{data.args.includeDamageCode ? 'Yes' : 'No'}</Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant={'caption'}>Verbose Output:</Typography>
                            <Typography variant={'body2'}>{data.args.verboseOutput ? 'Yes' : 'No'}</Typography>
                        </Box>
                        <Box mb={2}>
                            <Typography variant={'caption'}>Keep Negative-Negative Pairs:</Typography>
                            <Typography variant={'body2'}>{data.args.keepNegNegPairs ? 'Yes' : 'No'}</Typography>
                        </Box>
                    </div>
                ) : (
                    <div>
                        <Grid container spacing={1}>
                            <Grid item md={6} sm={12}>
                                <FormControl className={classes.predefinedFormControl} variant="outlined" size="small" style={{width: '100%'}} margin={'dense'}>
                                    <InputLabel id="mission-mix">Mission Mix</InputLabel>
                                    <Select
                                        labelId="mission-mix"
                                        id="mission-mix-outlined"
                                        value={data.args.missionMix}
                                        name={'args.missionMix'}
                                        onChange={(e) => setMissionMix(e)}
                                        label="Mission Mix"
                                    >
                                        <MenuItem value={''}><em>Select</em></MenuItem>
                                        {props.config.missionMixes?.map((mix, idx) => (
                                            <MenuItem key={idx} value={mix.name}><em>{mix.aircraft ? mix.aircraft + ' - ' : ''}{mix.name}</em></MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={6} sm={12}>
                                <FormControl className={classes.predefinedFormControl} variant="outlined" size="small" style={{width: '100%'}} margin={'dense'}>
                                    <InputLabel id="gag-cycle">GAG Cycle Type</InputLabel>
                                    <Select
                                        labelId="gag-cycle"
                                        id="gag-cycle-outlined"
                                        value={data.args.gagCycleType}
                                        name={'args.gagCycleType'}
                                        onChange={(e) => handleChange(e)}
                                        label="GAG Cycle Type"
                                    >
                                        <MenuItem value={''}><em>Select</em></MenuItem>
                                        <MenuItem value={'noGAG'}><em>NO GAG</em></MenuItem>
                                        <MenuItem value={'MinMax'}><em>MIN MAX</em></MenuItem>
                                        <MenuItem value={'MeanMean'}><em>MEAN MEAN</em></MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container>
                          <FormControlLabel
                              className={classes.predefinedFormControl}
                              control={
                                  <Checkbox
                                      checked={data.args.includeDamageCode}
                                      onChange={(e) => handleChange(e)}
                                      name="args.includeDamageCode"
                                      color="primary"
                                  />
                              }
                              label={
                                  <Typography className={classes.selectWithIcon}>
                                      Include Damage Code
                                      {data.args.missionMix && (
                                        <IconButton onClick={() => setIncludeDamageCodeDialog(true)} size="large">
                                            <InfoIcon />
                                        </IconButton>
                                      )}
                                  </Typography>
                              }
                          />
                          <FormControlLabel
                              className={classes.predefinedFormControl}
                              control={
                                  <Checkbox
                                      checked={data.args.verboseOutput}
                                      onChange={(e) => handleChange(e)}
                                      name="args.verboseOutput"
                                      color="primary"
                                  />
                              }
                              label={
                                  <Typography className={classes.selectWithIcon}>
                                      Verbose Output
                                  </Typography>
                              }
                          />
                          <FormControlLabel
                              className={classes.predefinedFormControl}
                              control={
                                  <Checkbox
                                      checked={data.args.keepNegNegPairs}
                                      onChange={(e) => handleChange(e)}
                                      name="args.keepNegNegPairs"
                                      color="primary"
                                  />
                              }
                              label={
                                  <Typography className={classes.selectWithIcon}>
                                    Keep Negative-Negative Pairs
                                  </Typography>
                              }
                          />
                        </Grid>
                        <FormControl>
                            <FormLabel>Output Files</FormLabel>
                            <FormGroup>
                                <div>
                                    <FormControlLabel
                                        control={<Checkbox color="primary" name={'args.outputFiles.AFGROW'} checked={data.args.outputFiles.AFGROW} onChange={e => handleChange(e)} />}
                                        label="AFGROW"
                                        classes={{label: classes.selectLabels}}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" name={'args.outputFiles.NASGRO'} checked={data.args.outputFiles.NASGRO} onChange={e => handleChange(e)} />
                                        }
                                        classes={{label: classes.selectLabels}}
                                        label={
                                            <Typography variant="body2" className={classes.selectWithIcon}>
                                                NASGRO
                                                {data.args.includeDamageCode && data.args.outputFiles.NASGRO && (
                                                    <Tooltip style={{marginLeft: '5px'}} title={'Damage Code is not included in NASGRO'} placement={'right'}>
                                                        <WarningIcon fontSize={'small'} color={'error'} />
                                                    </Tooltip>
                                                )}
                                                {data.args.outputFiles.NASGRO && (
                                                    <Tooltip style={{marginLeft: '5px'}} title={'Type 4 Multiblock'} placement={'right'}>
                                                        <InfoIcon fontSize={'small'} color={'disabled'} />
                                                    </Tooltip>
                                                )}
                                            </Typography>
                                        }
                                    />
                                </div>
                                <div>
                                  <FormControlLabel
                                      control={<Checkbox color="primary" name={'args.outputFiles.CRK2K'} checked={data.args.outputFiles.CRK2K} onChange={e => handleChange(e)}/>}
                                      label="CRK2K"
                                      classes={{label: classes.selectLabels}}
                                  />
                                  <FormControlLabel
                                      control={<Checkbox color="primary" name={'args.outputFiles.FLIPSPEC'} checked={data.args.outputFiles.FLIPSPEC} onChange={e => handleChange(e)}/>}
                                      label="FLIPSPEC"
                                      classes={{label: classes.selectLabels}}
                                  />
                                </div>
                            </FormGroup>
                        </FormControl>
                    </div>
                )}

            </CardContent>
        </Card>
    );
}

export default ASPECPredefinedData;