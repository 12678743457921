import * as React from 'react';
import TextField from '@material-ui/core/TextField';
// import { convertPixelsToRem } from '../../../../../helpers';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Checkbox,
} from '@material-ui/core/';
import { withStyles } from '@material-ui/styles';

/**
 *
 * Converts pixels to rems with base pixel unit of 16px
 *
 * @param  {number} unitValue - number of pixels
 * @returns string - rem value
 */
 const convertPixelsToRem = (unitValue) => {
  return `${(unitValue / 16).toFixed(2)}rem`;
};

const StringerTable = (props) => {
  const tableUnlocked = props.status !== "SUCCESSFUL";

  // used to setup styles for containers and tables
  const useStyles = makeStyles({
    container: {
      borderRight: '1px solid #797979',
      fontSize: `${convertPixelsToRem(12)} !important`,
    },
    table: {
      minWidth: 650,
    },
    tableRightBorder: {
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
    },

  });

  // used to setup styles for table rows
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: '#eff2f8',
      },
    },
  }))(TableRow);

  // used to setup styles for short text fields
  const ShortTextField = withStyles((theme) => ({
    root: {
      minWidth: 60,
      maxWidth: 85,
      '& .MuiInputBase-root': {
        height: 20,
        fontSize: `${convertPixelsToRem(12)} !important`,
      },
    },
  }))(TextField);


  const handleTextfieldChange = async (e, cbIdx, column, side, props) => {
    props.update(parseFloat(e.target.value), `data.stringers[${cbIdx}].${side}.${column}`);
  };

  const stringerColumns = ['wl', 'bl', 'skin', 'stringerArea', 'deltaArea', 'efc', 'efs', 'eft', 'efp', 'tanA', 'tanB', 'K'];
  const leftBorderColumns = ['wl']

  const getStringerStrn = (idx) => {
    return (idx + 1) * 100
  }

  const getField = (stringer, column, cbIdx, side) => {
    const value = getValue(stringer, column, side)
    return (
      <ShortTextField
        name={column}
        defaultValue={value}
        error={value ? Boolean(value.error) : false}
        focused={value ? value.focus : false}
        type={'text'}
        variant="outlined"
        onBlur={(e) => handleTextfieldChange(e, cbIdx, column, side, props)}
      />
    );

  };

  const getValue = (stringer, column, side='', digits=6) => {
    let val;
    if (side === 'left') {
      val = stringer.left[column];
    } else if (side === 'right') {
      val = stringer.right[column];
    } else {
      val = stringer[column];
    }
    return Math.round((val + Number.EPSILON) * 10**digits) / 10**digits // round to x digits
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked && props.data && props.data.stringers) {
      const newSelecteds = props.data.stringers.map((_, i) => i);
      props.setSelectedRows(newSelecteds);
      return;
    }
    props.setSelectedRows([]);
  };

  const handleClick = (event, elecIdent) => {
    const selectedIndex = props.selectedRows.indexOf(elecIdent);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selectedRows, elecIdent);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selectedRows.slice(1));
    } else if (selectedIndex === props.selectedRows.length - 1) {
      newSelected = newSelected.concat(props.selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selectedRows.slice(0, selectedIndex),
        props.selectedRows.slice(selectedIndex + 1)
      );
    }

    props.setSelectedRows(newSelected);
  };

  const isSelected = (elecIdent) => props.selectedRows.indexOf(elecIdent) !== -1;

  const rowCount = () =>
    props.data && props.data.stringers ? props.data.stringers.length : 0;

  // overflowX: 'unset' to avoid small vertical scroll issue (space after the last row)
  return (
    <TableContainer className="hideScrollbar" style={{overflowX: 'unset'}}>
      <Table className={useStyles.table} stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} style={{padding: 4}}/>
            <TableCell align="center" colSpan={11} style={{padding: 4}}>
              Right
            </TableCell>
            <TableCell align="center" colSpan={11} style={{padding: 4}}>
              Left
            </TableCell>
          </TableRow>
          <TableRow>
            {tableUnlocked && (
              <TableCell padding="checkbox" align='center' style={{padding: 4}}>
                <Checkbox
                  color="primary"
                  size="small"
                  style={{ padding: 2 }}
                  indeterminate={
                    props.selectedRows.length > 0 && props.selectedRows.length < rowCount()
                  }
                  checked={rowCount() > 0 && props.selectedRows.length === rowCount()}
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all circuit breakers',
                  }}
                />
              </TableCell>
            )}
            <TableCell align="center" style={{padding: 8}}><b>STRN</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>WL</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>BL</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>Skin</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>Stringer <br /> area</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>delta <br /> area</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>EFC</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>EFS</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>EFT</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>EFP</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>tan A</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>tan B</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>K</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>WL</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>BL</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>Skin</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>Stringer <br /> area</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>delta <br /> area</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>EFC</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>EFS</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>EFT</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>EFP</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>tan A</b></TableCell>
            <TableCell align="center" style={{padding: 8}}><b>tan B</b></TableCell>
            <TableCell
              align="center"
              style={{padding: 8}}
              ><b>K</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.stringers
            ? props.data.stringers.map((stringer, cbIdx) => {
                const isItemSelected = isSelected(cbIdx);
                console.log(isItemSelected)

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`stringer-${cbIdx}`}
                    selected={isItemSelected}
                    // style={{ height: 41 }}
                  >
                    {tableUnlocked && (
                      <TableCell padding="checkbox" align='center'>
                        <Checkbox
                          color="primary"
                          size="small"
                          style={{ padding: 0 }}
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, cbIdx)}
                        />
                      </TableCell>
                    )}
                      <TableCell
                        className={useStyles.tableRightBorder}
                        align='center'
                        key='str'
                        style={{
                          paddingTop: 0,
                          paddingBottom: 0,
                          paddingRight: 0,
                          paddingLeft: 0,
                        }}
                      >
                        {getStringerStrn(cbIdx)}
                      </TableCell>

                    {stringerColumns.map(function (column) {
                      return (
                        <TableCell
                          align={'center'}
                          key={column}
                          style={leftBorderColumns.includes(column) ? {
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingRight: 0,
                            paddingLeft: 0,
                            borderLeftWidth: 1,
                            borderLeftColor: 'lightgray',
                            borderLeftStyle: 'solid',
                          } : {
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingRight: 1,
                            paddingLeft: 1,
                          }}
                        >
                          {tableUnlocked
                            ? getField(stringer, column, cbIdx, 'right')
                            : getValue(stringer, column, 'right')}
                        </TableCell>
                      );
                    })}

                    {stringerColumns.map(function (column) {
                      return (
                        <TableCell
                          align={'center'}
                          key={column}
                          style={leftBorderColumns.includes(column) ? {
                            paddingTop: 0,
                            paddingBottom: 0,
                            paddingRight: 0,
                            paddingLeft: 0,
                            borderLeftWidth: 1,
                            borderLeftColor: 'lightgray',
                            borderLeftStyle: 'solid',
                          } : {
                            paddingTop: 4,
                            paddingBottom: 4,
                            paddingRight: 1,
                            paddingLeft: 1,
                          }}
                        >
                          {tableUnlocked
                            ? getField(stringer, column, cbIdx, 'left')
                            : getValue(stringer, column, 'left')}
                        </TableCell>
                      );
                    })}
                  </StyledTableRow>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StringerTable;
