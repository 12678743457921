import React from 'react';
import {Card, CardContent, makeStyles, CardHeader} from "@material-ui/core";
import ASPECStyles from "../../../../../styles/jss/components/apps/ASPECStyles";
import 'chart.js/auto';
import { Pie } from 'react-chartjs-2'

const useStyles = makeStyles(ASPECStyles);

const getChartData = (config, missionMix) => {
    let labels = [];
    let data = [];
    for(let i = 0; i < config.missionMixes?.length; i++) {
        if(config.missionMixes[i].name === missionMix) {
            for(let m = 0; m < config.missionMixes[i].missions.length; m++) {
                data.push(config.missionMixes[i].missions[m].percentage)
                labels.push(config.missionMixes[i].missions[m].name + ' ' + config.missionMixes[i].missions[m].percentage + '%')
            }
        }
    }
    return [data, labels];
}

const AnalysisInfo = props => {
    const classes = useStyles();
    const data = {
        labels: getChartData(props.config, props.missionMix)[1],
        boxWidth: 20,
        datasets: [
            {
                label: 'Mission Mix Variations',
                data: getChartData(props.config, props.missionMix)[0],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)'
                ],
                borderWidth: 1,
            },
        ]
    }

    const options = {
        legend: {
            onClick: function(event, legendItem) {},
            labels: {
                boxWidth: 10
            }
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    return data['labels'][tooltipItem['index']];
                }
            }
        },
        maintainAspectRatio: false
    }

    const lineData = {
        labels: ["Taxi Out", "Takeoff", "Departure", "Climb", "Cruise", "Descent", "Approach", "Landing roll", "Taxi in"],
        datasets: [{
            label: "My Dataset",
            backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)',], // now we can pass in an array of colors (before it was only 1 color)
            borderColor: 'rgba(255, 99, 132, 0.2)',
            pointBackgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)', 'rgba(255, 99, 132, 0.2)',],
            fill: true,
            data: [0, 0, 0, 10, 40, 40, 10, 0, 0]
        }]
    }

    const lineOptions = {
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 10,
                bottom: 0
            }
        },
        responsive: true,
        aspectRatio: 1,
        elements: {
            line: {
                tension: 0
            }
        },
        legend: {
            display: false,
        },
        tooltips: {
            enabled: false,
            footerAlign: 'center'
        },
        scales: {
            xAxes: [{
                gridLines: {
                    offsetGridLines: true,
                    display: false,
                },
                ticks: {
                    maxRotation: 90,
                    minRotation: 90
                }
            }],
            yAxes: [{
                display: false,
            }]
        }
    }
    return(
        <Card style={{height: '100%'}}>
            <CardHeader
                title={'Mission Mix Variation'}
                classes={{
                    title: classes.cardHeaderTitle,
                    root: classes.cardHeaderRoot
                }} />
            <CardContent style={{height: '80%'}} >
                <Pie data={data} options={options} height={308}/>
            </CardContent>
        </Card>
    )
}

export default AnalysisInfo;